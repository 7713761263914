import { FC } from "react";
import { ModelTypes } from "~/zeus";
import { Wrap, WrapItem, Tag, StyleProps, Box } from "@chakra-ui/react";

export const Tags: FC<
  StyleProps & {
    tags: ModelTypes["Tag"][] | Array<any>;
    func?: (...args: any) => void;
  }
> = ({ tags, func, ...styleProps }) => {
  if (tags?.length === 0) {
    return <></>;
  }
  const handleClick = (value: string) => {
    if (func) func(`/event?search=${encodeURIComponent(value)}&page=1`);
  };
  return (
    <Box>
      <Wrap>
        {tags?.map((tag) => {
          return (
            <WrapItem
              key={tag?.id}
              onClick={() => handleClick(tag?.name)}
              zIndex={2}
              h={"full"}
            >
              <Tag
                color="brand.grey"
                fontSize={["sm"]}
                borderRadius={99}
                bgColor="brand.light-grey"
                px={[3]}
                py={[1]}
                _hover={{ opacity: 0.8 }}
                {...styleProps}
              >
                {tag?.name}
              </Tag>
            </WrapItem>
          );
        })}
      </Wrap>
    </Box>
  );
};
